import {
	isRouteErrorResponse,
	useParams,
	useRouteError,
	type ErrorResponse,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

type StatusHandler = (info: {
	error: ErrorResponse;
	params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler;
	statusHandlers?: Record<number, StatusHandler>;
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	const params = useParams();

	if (typeof document !== "undefined") {
		console.error(error);
	}

	return (
		<div className="container flex items-center justify-center p-20 text-h2 bg-red-50 border-red-500 border rounded-lg text-red-500">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	);
}

function getErrorMessage(error: unknown) {
	if (typeof error === "string") return error;
	if (
		error &&
		typeof error === "object" &&
		"message" in error &&
		typeof error.message === "string"
	) {
		return error.message;
	}
	console.error("Unable to get error message for error", error);
	return "Unknown Error";
}
